@import './globals.scss';

.reset-password {
  margin-right: 20px;
  position: relative;
  top: -24px;
}

.container {
  padding-bottom: 47px;
}

.link-button {
  font-size: 14px;
  font-weight: 600 !important;
}

.button {
  width: 256px;
} 

.sign-in {
  margin-top: 56px;
}

.back-link {
  margin-top: 30px !important;
}

@include media-breakpoint-down(sm, $grid-breakpoints) {
  .button {
    width:160px;
  } 
} 