@import '../../styles/globals.scss';

.container {
  padding-top: 24px;
  padding-bottom: 12px;
}

.button {
  width:200px;
  @include media-breakpoint-down(sm, $grid-breakpoints) {
    width: 130px !important;
  }
}
